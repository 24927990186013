import React from "react"
import { graphql } from "gatsby"
import Page from "gatsby-theme-nieuwbouw/src/components/Page";
import {Col, Container, Row} from "react-bootstrap"
import {IPage} from "../types";
import {StaticImage} from "gatsby-plugin-image";
import {Header} from "../components/Header";

interface Props {
    data: PageQueryData
}
export default ({ data }: Props) => {
    return (
        <Page pageTitle={data.file.childMarkdownRemark.frontmatter.title} pageDescription={data.file.childMarkdownRemark.frontmatter.description}>
            <Header>
                <StaticImage src={'../img/sfeer/Sfeer-familie-04.jpg'} aspectRatio={3} alt={'header'}/>
            </Header>
            <Container
                fluid={'lg'}
                className={"my-5 text-center text-md-left"}
            >
                <section className={'my-5'}>
                <h2>Keuzevrijheid</h2>
                <Row>
                    <Col md={7}>
                        <p className={'lead'}>
                            Jouw smaak, jouw keuken. Kwestie van jouw eigen ingrediënten toevoegen.
                            In de basis van het appartement is geen keuken opgenomen. De smaken zijn zo divers dat deze keuze geheel aan jou wordt overgelaten.
                            Samen met de projectleverancier kan je jouw keuken uitkiezen en laten plaatsen.
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                       <p>
                           De badkamers in de appartementen worden wel opgeleverd tegelwerk en sanitair. De badkamer wordt opgeleverd met toilet, wastafel en douche-opstelling.
                            Uiteraard is dit naar wens zelf aan te passen. Zoals jij het graag ziet.
                       </p>
                        <h6>Duurzaamheid</h6>
                        <p>
                            Het hele Kokkelhof wordt duurzaam ontwikkeld. In Binnengaats zijn de appartementen extreem energiezuinig en hebben ze een minimale energiebehoefte. Dit resulteert in een appartement wat in de zomer altijd koel is en in de winter lekker warm. Door de vele zonnepanelen op het dak zijn de appartementen ook bijna nul-op-de-meter.
                        </p>
                        <p>
                            Het gebouw zelf wordt gemaakt uit hergebruikt beton. Dit type beton bestaat voor de helft uit secundair materiaal, waarmee ook nog eens een CO2-reductie van 50% wordt behaald.
                        </p>
                        <p>
                            De gevelbekleding bij de entree bestaat uit duurzaam hout. Er is gekozen voor snelgroeiend loofhout, Fraké. De leverancier geeft een levensduur van 50 jaar aan, wat betekent dat het hout binnen de levensduur alweer volgroeid is.
                        </p>
                        <p>
                            Wie duurzaamheid zegt denkt automatisch aan mobiliteit. Zowel bewoners van Vaartsche Weelde als de bewoners Binnengaats kunnen gebruik maken van elektrische deelauto’s. Deze zijn alleen te gebruiken door de bewoners. Ga je op pad voor een kleine boodschap? Dan kun je net zo makkelijk de elektrische deel(bak)fiets of scooter pakken die ook bij de woningen horen.
                        </p>
                    </Col>
                    <Col md={6}>
                        <StaticImage src={'../img/sfeer/Duurzaam 1.jpg'} className={'rounded'} alt={'header'}/>
                    </Col>
                </Row>
                </section>
                <section className={'my-5'}>
                <Row>
                    <Col md={6}>
                        <StaticImage
                            className={'rounded'}
                            src={'../img/sfeer/EnNaruurlijk-week-3-0225@2x.png'}
                            alt={'stal'}
                        />
                    </Col>
                    <Col md={6}>
                        <h6>Collectieve verwarming</h6>
                        <p>
                            De komende jaren zal Ennatuurlijk duurzaam warmte leveren aan de 3.000 woningen in Zeewolde. De leveringen van deze groene warmte is een uniek energiezuinig project. De groene warmte wordt geproduceerd met een warmtekrachtkoppeling op biogas van melkveehouderij Van Beek uit Zeewolde. Dit wordt verkregen uit mest van koeien, vermengd met restanten van akkerbouw, de voedingsindustrie en grasmaaisel uit natuurgebieden. Dit principe is niet alleen goed voor het milieu, maar levert uiteindelijk ook een kostenbesparing op voor de inwoner.
                        </p>
                    </Col>
                </Row>
                </section>
            </Container>
        </Page>
    );
}

interface PageQueryData {
    file: {
        childMarkdownRemark: IPage
    }
}

export const pageQuery = graphql`
    query {
        file(name: {eq: "extra"}) {
            childMarkdownRemark {
                ...Page
            }
        }
    }
`
