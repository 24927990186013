import React, {ReactNode} from 'react'
import {IGatsbyImageData} from "gatsby-plugin-image";

interface Props {
    children?: ReactNode
}
export const Header = ({children}: Props) => {
   return (
       <header className={"position-relative"}>
           {children}
           <div
               className={'position-absolute'}
               style={{
                   bottom: 0,
                   width: '100%'
               }}
           >
               <svg viewBox="0 0 500 150" preserveAspectRatio="none" style={{height: '150px', width: '100%'}}>
                   <path d="M-5.07,104.11 C130.92,219.56 343.68,27.13 500.00,128.78 L500.00,150.00 L0.00,150.00 Z" style={{stroke: 'none', fill: '#fff'}}></path>
               </svg>
           </div>
       </header>
   );
}